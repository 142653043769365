<template>
  <picture :id="`text-${textId}`" class="story-text" @mousedown="textDown" @mouseup="textUp" v-html="cleanHTML(post.message)"></picture>
</template>

<script>
export default {
  props: ["src", "poster", "index", "post", "refs"],
  data: function() {
    return {
      textId: this.$global.fastID(),
      progressBar: null,
      duration: 6000,
    };
  },
  methods: {
    textDown: function() {
      if (!this.progressBar) return;
      this.progressBar.pause();
    },
    textUp: function() {
      if (!this.progressBar) return;
      this.progressBar.play();
    },
    textStop: function({ index }) {
      if (!this.progressBar) return;
      if (index > this.index) {
        this.progressBar.complete();
      } else {
        this.progressBar.cancel();
      }
    },
    textStarted: async function() {
      if (this.progressBar) {
        this.progressBar.start({ duration: this.duration, index: this.index });
      }
    },
    currentSlide: async function({ index }) {
      if (!this.text) {
        await this.sleep(1000);
        return this.currentSlide({ index });
      }
      this.progressBar = this.refs[`bar${this.post._id}`][0];
      if (index === this.index) {
        this.textStarted();
      } else {
        this.textStop({ index });
      }
    },
  },
  computed: {
    text: function() {
      return document.getElementById(`text-${this.textId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.story-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include Flex(column, center, center);
  color: #fff;
}
</style>
